var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Grafik Penjualan")])], 1), _c('b-card-body', [_c('b-card-text', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', [_c('h5', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Tanggal Awal")]), _c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "YYYY-MM-DD"
    },
    model: {
      value: _vm.start_date,
      callback: function callback($$v) {
        _vm.start_date = $$v;
      },
      expression: "start_date"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', [_c('h5', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Tanggal Akhir")]), _c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "YYYY-MM-DD"
    },
    model: {
      value: _vm.end_date,
      callback: function callback($$v) {
        _vm.end_date = $$v;
      },
      expression: "end_date"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', [_c('h5', {
    staticClass: "font-weight-bold"
  }, [_vm._v("-")]), _c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.getData
    }
  }, [_vm._v("Update Grafik")])], 1)], 1)], 1)], 1), _vm.loaded ? _c('chartjs-component-line-chart', {
    attrs: {
      "height": 500,
      "data": _vm.chartpenjualan.data,
      "options": _vm.chartpenjualan.options,
      "plugins": _vm.plugins
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }