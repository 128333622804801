var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('div', [_c('b-card-title', {
    staticClass: "mb-1"
  }, [_vm._v(" Grafik Penjualan ")])], 1), _c('div', {
    staticClass: "d-flex align-items-center flex-wrap mt-sm-0 mt-1"
  }, [_c('h5', {
    staticClass: "font-weight-bolder mb-0 mr-1"
  }, [_vm._v("Rp.2.000.000")])])]), _c('b-card-body', [_c('vue-apex-charts', {
    attrs: {
      "type": "line",
      "height": "400",
      "options": _vm.apexChatData.dataPenjualan.chartOptions,
      "series": _vm.apexChatData.dataPenjualan.series
    }
  })], 1)], 1), _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('div', [_c('b-card-title', {
    staticClass: "mb-1"
  }, [_vm._v(" Grafik Pendapatan ")])], 1), _c('div', {
    staticClass: "d-flex align-items-center flex-wrap mt-sm-0 mt-1"
  }, [_c('h5', {
    staticClass: "font-weight-bolder mb-0 mr-1"
  }, [_vm._v("Rp.2.500.000")])])]), _c('b-card-body', [_c('vue-apex-charts', {
    attrs: {
      "type": "line",
      "height": "400",
      "options": _vm.apexChatData.dataPendapatan.chartOptions,
      "series": _vm.apexChatData.dataPendapatan.series
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }