var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('statistic-card-horizontal', {
    attrs: {
      "icon": "UsersIcon",
      "statistic": _vm.chart.user,
      "statistic-title": "Pengguna"
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('statistic-card-horizontal', {
    attrs: {
      "icon": "StarIcon",
      "color": "success",
      "statistic": _vm.chart.paket,
      "statistic-title": "Paket Tryout"
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('statistic-card-horizontal', {
    attrs: {
      "icon": "BookIcon",
      "color": "info",
      "statistic": _vm.chart.ebook,
      "statistic-title": "E-book"
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('statistic-card-horizontal', {
    attrs: {
      "icon": "FileTextIcon",
      "color": "warning",
      "statistic": _vm.chart.article,
      "statistic-title": "Artikel"
    }
  })], 1)], 1), _c('bar')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }