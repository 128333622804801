<template>
  <div class="">
    <b-card no-body>
      <b-card-header>
        <!-- title and subtitle -->
        <div>
          <b-card-title class="mb-1"> Grafik Penjualan </b-card-title>
        </div>
        <!--/ title and subtitle -->

        <!-- badge -->
        <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
          <h5 class="font-weight-bolder mb-0 mr-1">Rp.2.000.000</h5>
        </div>
        <!--/ badge -->
      </b-card-header>

      <b-card-body>
        <vue-apex-charts
          type="line"
          height="400"
          :options="apexChatData.dataPenjualan.chartOptions"
          :series="apexChatData.dataPenjualan.series"
        />
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <!-- title and subtitle -->
        <div>
          <b-card-title class="mb-1"> Grafik Pendapatan </b-card-title>
        </div>
        <!--/ title and subtitle -->

        <!-- badge -->
        <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
          <h5 class="font-weight-bolder mb-0 mr-1">Rp.2.500.000</h5>
        </div>
        <!--/ badge -->
      </b-card-header>

      <b-card-body>
        <vue-apex-charts
          type="line"
          height="400"
          :options="apexChatData.dataPendapatan.chartOptions"
          :series="apexChatData.dataPendapatan.series"
        />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BBadge,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import apexChatData from "./apexChartData";

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  data() {
    return {
      apexChatData,
      chart: {},
    };
  },
  // methods: {
  //   getData() {
  //     this.loading = true;
  //     const params = {
  //       // type: "page",
  //       filter_month: 8,
  //       // filter_month:
  //     };
  //     this.$store
  //       .dispatch("dashboard/index", params)
  //       .then((response) => {
  //         this.loading = false;
  //         let chart = response.data.data;
  //         this.chart = chart;
  //         this.totalRows = this.chart.length;
  //       })
  //       .catch((error) => {
  //         this.loading = false;
  //         this.$root.$emit("errorData", error);
  //       });
  //   },
  // },
  // created() {
  //   this.getData();
  // },
};
</script>
